.card-div-container {
  display: inline-flex;
  width: calc(33.3% - 24px);
  height: 150px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #1e43ad;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}

.card-span-title {
  font-size: 14px;
  color: #fff;
}
.card-img-icon {
  width: 18px;
  height: 18px;
}
.card-div-data {
  width: 100%;
  font-size: 26px;
  color: #fff;
  line-height: 36px;
  margin-left: -5px;
}
