.notice-div-container {
  max-width: 1330px;
  margin: auto;
  li:first-child {
    border-top: 1px solid #eeeeee;
    margin-top: 70px;
  }

  li {
    height: 280px;
    border-bottom: 1px solid #eeeeee;
    /* Spacing */
    margin: 0px;
    /* padding: 8px 0px;*/
    display: flex;
    padding-top: 38px;
    box-sizing: border-box;
  }
  //890*480 = 445*240
  .notice-div-img {
    width: 319px;
    height: 204px;
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .notice-date {
    /*    display: inline-block;
    float: left;*/
    font-size: 14px;
    line-height: 28px;
    color: #1e43ad;
  }
  .notice-div-content {
    display: inline-block;
    width: calc(100% - 445px);
    padding-left: 60px;
    padding-right: 37px;
    /*  padding-top: 20px;*/

    .notice-title {
      font-size: 23px;
      line-height: 48px;
      color: #6a9bff;
    }
    .notice-summary {
      margin-top: 20px;
      /*      color: #666666;*/
      font-size: 14px;
      height: 86px;
    }
    .notice-footer {
      .notice-date {
        display: inline-block;
        float: left;
        font-size: 21px;
        line-height: 48px;
        color: #6a9bff;
      }
      .notice-link {
        display: inline-block;
        float: right;
        font-size: 16px;

        line-height: 48px;
        color: #6a9bff;
      }
    }
  }
}
.pagination {
  display: flex;
  margin-left: 15px;
  margin-top: 45px;
  margin-bottom: 150px;
  justify-content: center;
  /* Border */
  /*  border: 1px solid rgba(0, 0, 0, 0.3);*/
  border-radius: 4px;
  .leftArrow {
    position: relative;
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-top: 2px solid #1e43ad;
    border-right: 2px solid #1e43ad;
    transform: rotate(-135deg);
  }
  .rightArrow {
    position: relative;
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #1e43ad;
    border-left: 2px solid #1e43ad;
    transform: rotate(-135deg);
  }
}

.pagination__item {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 5px;
  background-color: #f7f5f6;
  color: #717072;
  font-size: 13px;
  /* Right border */
  /*border-right: 1px solid rgba(0, 0, 0, 0.3);*/
}

.pagination__item:hover {
  cursor: pointer;
}
.pagination__item.active {
  background-color: #1e43ad;
  color: #fff;
}
.pagination__item + .pagination__item {
  /* No right border */
  border-right: none;
}
