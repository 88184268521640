.barChart-div-block {
  display: inline-block;
  width: calc(50% - 16px);
  height: 300px;
  margin-top: 35px;
  /*  background: #ff85c8;*/
  padding: 20px;
  box-sizing: border-box;
}

.barChart-div-title {
  font-size: 18px;
}
.barChart-div-echart {
  height: 150px;
}
