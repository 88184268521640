/* 左侧面板-转速表 */
.leftPanel-div-tachometerBlock {
  width: calc(50% - 16px);
  height: 250px;
  /*  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;*/
  display: inline-flex;
  flex-direction: column;
}
/* 转速图表 */
.tachometerBlock-div-meterChart {
  height: 162px;
  /*  background-color: gold;*/
}
/*半圈*/
.meterChart-div-progressContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.meterChart-div-innerMeter {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: calc(50% - 34px);
  transform: translateX(-50%);
  height: 80px;
  width: 150px;
  /*  background-color: #85e8ff;*/
}

.meterChart-div-outerMeter {
  height: 140px;
  width: 250px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  z-index: 1;
  /*  background-color: pink;*/
}

.tachometerBlock-div-info {
  height: 64px;
  padding: 0 36px;
  /*  background-color: purple;*/
}

.tachometerBlock-div-info .infoText {
  font-size: 14px;
  line-height: 22px;
}

.meterInfo-div-supText {
}

.meterInfo-div-subText {
}

.meterInfo-div-progress {
  display: inline-block;
  font-size: 50px;
  color: #1e43ad;
  line-height: 50px;
  position: relative;
  left: calc(100% - 68px);
  top: -100%;
  transform: translateY(20px);
}
.meterInfo-span-progressUnit {
  font-size: 14px;
}
