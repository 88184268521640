.patent__condition {
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset !important;
  }
  .ant-radio-button-wrapper {
    border-left-width: 1px;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #1e43ad;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #1e43ad;
    border-color: #1e43ad;
  }
}
.search--input {
  width: 784px;
  height: 32px;
  margin-top: 64px;
  margin-bottom: 18px;

  .container {
    display: flex;
    /* Take full size */
    width: 100%;
    height: 100%;
    /*   border-left: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;*/
  }

  .prefix__icon {
    padding-left: 11px;
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .container__input {
    /* Take the remaining width */
    padding-left: 5px;
    flex: 1;
    border: none;
    outline: none;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  .container__addon {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .search__label {
    width: 62px;
    font-size: 14px;
    color: #fff;
    background: #1e43ad;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .search__label:hover {
    cursor: pointer;
  }
}

.patent-div-container {
  /*  max-width: 1330px;*/
  margin: auto;

  table {
    border-collapse: collapse;
    border: 1px solid #dddddd;
    font-size: 14px;
    tr,
    td {
      border: 1px solid #dddddd;
    }
  }

  .rateContainer {
    display: inline-block;
    /*height: 80px;*/
    position: absolute;
    right: 15px;
    bottom: 5px;
  }
  .ant-rate {
    color: #1e43ad;
  }

  .patent-div-header {
    margin-top: -25px;
    height: 25px;
    position: relative;

    .metaInfo {
      color: #666666;
      font-size: 12px;
    }
  }

  .patent-item:first-child {
    // border-top: 1px solid #eeeeee;
    margin-top: 70px;
  }

  .patent-item {
    height: 165px;
    border-bottom: 1px solid #eeeeee;
    /* Spacing */
    margin: 0px;
    /* padding: 8px 0px;*/
    /*   padding-top: 38px;*/
    box-sizing: border-box;
    margin-top: 32px;
    border-top: 1px solid #ffffff;
  }
  //890*480 = 445*240
  .notice-div-img {
    width: 155px;
    height: 104px;
    display: inline-block;
    display: flex;
    justify-content: space-evenly;
    margin-top: 26px;

    .patentIcon {
      font-size: 13px;
      display: inline-block;
      margin: 0 auto;
	  text-align: center;
	  
      div {
        text-align: center;
        width: 28px;
        height: 22px;
        margin: 0 auto;
      }
    }
  }
  .notice-date {
    /*    display: inline-block;
    float: left;*/
    font-size: 18px;
    line-height: 28px;
    color: #1e43ad;
    &:hover {
      cursor: pointer;
    }
    .patentIcon {
      display: inline-flex;
      float: right;
/*      flex-direction: column;*/
      justify-content: center;
      align-items: center;
      width: 70px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      label {
        margin-left: 10px;
      }
    }

  }
  .notice-div-content {
    display: inline-block;
    width: calc(100% - 240px);
    /*    padding-left: 60px;*/
    padding-right: 37px;
    /*  padding-top: 20px;*/

    .notice-title {
      font-size: 23px;
      line-height: 48px;
      color: #6a9bff;
    }
    .notice-summary {
      margin-top: 10px;
      /*      color: #666666;*/
      font-size: 14px;
      line-height: 26px;
      height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      &:hover {
        cursor: pointer;
      }
    }
    .notice-footer {
      .notice-date {
        display: inline-block;
        float: left;
        font-size: 21px;
        line-height: 48px;
        color: #6a9bff;
      }
      .notice-link {
        display: inline-block;
        float: right;
        font-size: 16px;

        line-height: 48px;
        color: #6a9bff;
      }
    }
  }
}

.patent-content {
  display: flex;
  justify-content: space-between;
}
.pagination {
  display: flex;
  margin-left: 15px;
  margin-top: 45px;
  margin-bottom: 150px;
  justify-content: center;
  /* Border */
  /*  border: 1px solid rgba(0, 0, 0, 0.3);*/
  border-radius: 4px;
  .leftArrow {
    position: relative;
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-top: 2px solid #1e43ad;
    border-right: 2px solid #1e43ad;
    transform: rotate(-135deg);
  }
  .rightArrow {
    position: relative;
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #1e43ad;
    border-left: 2px solid #1e43ad;
    transform: rotate(-135deg);
  }
}

.pagination__item {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 5px;
  background-color: #f7f5f6;
  color: #717072;
  font-size: 13px;
  /* Right border */
  /*border-right: 1px solid rgba(0, 0, 0, 0.3);*/
}
.pagination__item.active {
  background-color: #1e43ad;
  color: #fff;
}

.pagination__item:hover {
  cursor: pointer;
}
.pagination__item + .pagination__item {
  /* No right border */
  border-right: none;
}

.patentDetail-title {
  border-bottom: 1px solid #eeeeee;
  color: #1e43ad;
  font-size: 24px;
  margin-top: 85px;
  padding: 5px 0;
}
.patentDetail-detail {
  font-size: 14px;
  line-height: 48px;
}

.patentDetail-applyTable {
  width: 100%;
  margin-top: 10px;
  td {
    height: 34px;
    padding-left: 12px;
  }
}

.patentDetail-label {
  font-size: 14px;
  text-align: center;
  color: #1e43ad;
  margin-top: 85px;
}
.patentDetail-rateTable {
  width: 100%;
  margin-top: 20px;
  td {
    height: 36px;
    width: 33.3%;
    text-align: center;
  }
}
.patentDetail-advTable {
  width: 100%;
  margin-top: 20px;
  /*border-left: 1px solid #dddddd;
  border-top: 1px solid #dddddd;*/
  border-right: unset !important;
  border-bottom: unset !important;

  section {
   /* border: 1px solid #dddddd;*/
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    display: inline-block;
    height: 95px;
    width: 50%;
    text-align: center;
  }
}

.solidButton {
  display: inline-block;
  height: 32px;
  border: 1px solid #1e43ad;
  border-radius: 16px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 32px;
  box-sizing: border-box;
}
.solidButton:hover {
  cursor: pointer;
}

.patenDetail-file {
  display: flex;
  justify-content: space-evenly;
  margin-top: 120px;
  margin-bottom: 90px;

  .contact {
    display: inline-block;
    font-size: 14px;
    text-align: center;
  }
}

.fileButton {
  display: inline-block;
  height: 32px;
  border-radius: 16px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 32px;
  box-sizing: border-box;
  background: #1e43ad;
  color: #fff;
  min-width: 210px;
  text-align: center;
}
.fileButton-disabled {
	background: #999999;
}
