.customDialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  box-sizing: border-box;
  z-index: 999;
  .bg {
    width: 1000vw;
    height: 1000vh;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .fullDialog {
    position: absolute;
    max-width: 1200px;
    width: 65%;
    min-height: 800px;
    max-height: 85%;
    overflow: auto;
    margin: auto;
    background-color: #ffffff;
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 58px 66px 58px;
    box-sizing: border-box;
  }
  .dialogTitle {
    font-size: 30px;
    height: 88px;
    border-bottom: 1px solid #eeeeee;
  }
  .dialogTime {
    margin-top: 35px;
    font-size: 14px;
    color: #1e43ad;
  }
  .dialogContent {
    margin-top: 12px;
    font-size: 14px;
    line-height: 28px;
    img {
      max-width: 100%;
    }
  }
  .dialogImg {
    max-width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .buttonArea {
    text-align: center;
    margin-top: 34px;
  }
  .closeIcon {
    height: 23px;
    width: 23px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .closeIcon:hover {
    cursor: pointer;
  }

  .closeIcon:after {
    position: relative;
    content: "×";
    font-size: 45px;
    color: #ce003b;
    font-weight: lighter;
    top: -21px;
    left: -4px;
  }

  .primaryButton {
    width: 186px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #1e43ad;
    font-size: 14px;
    color: #1e43ad;
    background-color: #fff;
  }
  .primaryButton:hover {
    cursor: pointer;
  }

  .closeButton {
    width: 186px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #ce003b;
    font-size: 14px;
    color: #c10025;
    background-color: #fff;
  }
  .closeButton:hover {
    cursor: pointer;
  }
}
