/* 顶部标题 */
.activity-div-subTitleContainer {
  align-items: center;
  height: 160px;
  width: 1070px;
  /* width: calc(100% - 64px); */
  /* max-width: 1792px; */
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 auto;
  justify-content: center;

  .titleContainer-div-content {
    width: 180px;
    padding: 0 24px;
    font-size: 48px;
    text-align: center;

    .zh {
      font-size: 28px;
      letter-spacing: 3px;
    }
    .en {
      font-size: 14px;
      color: #666666;
    }
  }

  .titleContainer-div-separator {
    border-bottom: 1px solid #c4c4c4;
    height: 1px;
    width: calc(50% - 400px);
  }
}
