.footer-container {
  max-width: 1792px;
  min-width: 1280px;
  margin: 0 auto;
  height: 100%;
}
.footer {
  /*  width: 100vw;*/
  width: 100%;
  height: 180px;
  background: #333333;

  .footer-info {
    display: flex;
    height: calc(100% - 28px);
    /* padding: 20px 10%; */
    box-sizing: border-box;
    padding-left: 64px;
    padding-right: 64px;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .info-left {
    display: inline-block;
    width: 25%;
	visibility: hidden;
  }
  .info-right {
    display: inline-flex;
    margin-left: 26px;
    width: 65%;
    padding-top: 24px;
  }
  .info-logo {
    width: 275px;
    height: 47px;
    margin-top: 24px;
    background: #ffffff;
    display: inline-block;
  }
  .info-text {
    margin-top: 18px;
    font-size: 12px;
    color: #999999;
  }
  .info-unit {
    color: #ffffff;
    font-size: 14px;
    div {
      line-height: 24px;
    }
  }

  .info-nav {
    font-size: 12px;
    color: #999999;
    margin-left: 64px;

    div:first-of-type {
      color: #ffffff;
      font-size: 14px;
      margin-bottom: 14px;
    }
    div:not(:first-child) {
      line-height: 25px;
    }
  }

  .qr1 {
    display: inline-block;
    width: 96px;
    height: 96px;
    background: #ffffff;
  }

  .footer-text {
    line-height: 28px;
    text-align: center;
    font-size: 10px;
    color: #ffffff;
    height: 28px;
    background: #000000;
  }
}
