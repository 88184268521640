// global classes
.center {
  position: absolute;
  top: 50%;
  left: 50%;
}
.txt-center {
  text-align: center;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination-item-active {
  border-color: #1e43ad;
  background-color: #1e43ad;
  color: #ffffff;
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
  color: #fff !important;
}

.ant-pagination-item:hover {
  border-color: #1e43ad;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #1e43ad;
}
