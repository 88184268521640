.small-circular {
  $width: 154px;
  $height: 154px;

  $innerWidth: 70px;
  $innerHeight: 140px;
  .circular {
    height: $width;
    width: $height;
    position: absolute;
    /* transform: scale(2); */
    transform: rotate(-90deg);
    /*  zoom: 2;*/
    left: calc(50% - #{$width/2});
    top: 10%;
  }
  .circular .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: calc(50% - 2px);
    height: $innerHeight;
    width: $innerWidth;
    margin: #{0-$innerHeight/2} 0 0 0;
    background: #fff;
    /*  border-radius: 100%;*/
    border-top-right-radius: $innerHeight;
    border-bottom-right-radius: $innerHeight;
  }
  .circular .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #dddddd;
    -webkit-border-radius: 100%;
    clip: rect(0px, $height, $width, $width/2);
  }
  .circle .bar .progress {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, $height/2, $width, 0px);
    /*  background: #4158d0;*/
    background: linear-gradient(
      45deg,
      rgba(30, 67, 173, 0.5) 0,
      rgba(30, 67, 173, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(30, 67, 173, 0.5) 50%,
      rgba(30, 67, 173, 0.5) 75%,
      transparent 75%,
      transparent
    );
    background-size: 4px 4px;
  }
  .circle .left .progress {
    z-index: 1;
    animation: left 4s linear both;
  }

  @keyframes left {
    100% {
      transform: rotate(180deg);
    }
  }
  .circle .right {
    transform: rotate(180deg);
    z-index: 3;
  }
  .circle .right .progress {
    animation: right 4s linear both;
    animation-delay: 4s;
  }
  @keyframes right {
    100% {
      transform: rotate(180deg);
    }
  }
}
