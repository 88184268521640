.dataDashboard-div-container {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 64px;
}

.hover-area {
  position: absolute;
  display: inline-block;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 99;
}

/* 顶部标题 */
.dataDashboard-div-titleContainer {
  align-items: center;
  height: 160px;
  width: calc(100% - 64px);
  max-width: 1792px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: -70px;
}

.titleContainer-div-content {
  width: 812px;
  padding: 0 24px;
  font-size: 48px;
  text-align: center;
}

.titleContainer-div-separator {
  border-bottom: 1px solid #1e43ad;
  height: 1px;
  width: calc(50% - 430px);
}
.titleContainer-div-separator img {
  max-height: 100%;
  max-width: 100%;
}

/* 左侧面板 */
.dataDashboard-div-leftPanel {
  /*  border: 1px red solid;*/
  box-sizing: border-box;
  width: 576px;
  width: calc(33.3% - 22px);

  /*  height: 100px;*/
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dataDashboard-div-middlePanel {
  /*  border: 1px red solid;*/
  box-sizing: border-box;
  width: 576px;
  width: calc(33.3% - 22px);
  /*height: 100px;*/
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 32px;
}

.dataDashboard-div-rightPanel {
  /*  border: 1px red solid;*/
  box-sizing: border-box;
  width: 576px;
  width: calc(33.3% - 22px);
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 32px;
}

.dashDashboard-block {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

@media (max-width: 1920px) {
  .dataDashboard-div-container {
    max-width: calc(100% - 64px);
  }
}

@media (max-width: 1680px) {
  .dataDashboard-div-container {
    /*  1620*0.9=1460  */
    min-width: 1450px;
    zoom: 0.9;
  }
}

@media (max-width: 1465px) {
  .tachometerBlock-div-info {
    padding-left: 14px;
  }
  .dataDashboard-div-container {
    /*  1620*0.9=1460  */
    min-width: 1440px;
  }
  .meterInfo-div-progress {
    font-size: 46px;
    left: calc(100% - 42px);
  }
  .card-div-data {
    font-size: 28px;
  }
  .stackArea-div-title {
    left: 25px;
    font-size: 17px;
  }
  .stackArea-div-title label {
    font-size: 32px;
  }
}
@-moz-document url-prefix() {
  @media (max-width: 1700px) {
    .tachometerBlock-div-info {
      padding-left: 14px;
    }
    .dataDashboard-div-container {
      /*  1620*0.9=1460  */
      min-width: 1440px;
    }
    .meterInfo-div-progress {
      font-size: 46px;
      left: calc(100% - 42px);
    }
    .card-div-data {
      font-size: 28px;
    }
    .stackArea-div-title {
      left: 25px;
      font-size: 17px;
    }
    .stackArea-div-title label {
      font-size: 32px;
    }
  }
}

.yp-list {
  width: 100%;
  height: calc(100% - 160px);
  font-size: 16px;
  overflow-y: hidden;
}
.yp-list li {
  line-height: 36px;
}
.yp-list li:hover {
  cursor: pointer;
}

.yp-mid {
  padding-top: 32px;
}
.yp-mid .card-img-icon{
  display: none;
}

.yp-mid .card-div-container {
  width: calc(50% - 11px);
}
