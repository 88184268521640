.home-div-bannerContainer {
  position: relative;
  img {
    width: 100%;
  }
  .banner-div-enName,
  .banner-div-zhName {
    font-size: 54px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }

  .banner-div-enName {
  }
  .banner-div-zhName {
    top: 30%;
    transform: translate(-50%, 100%);
  }

  .banner-div-dashboard {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 23%);
    bottom: 0px;
    background: #ffffff;
    border-radius: 10px;
    width: 80%;
    height: 33%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 15px 0px;
  }

  .dashboard-div-card {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .dashboard-div-card .dataValue {
    color: #1e43ad;
    font-size: 60px;
    font-weight: lighter;
  }
  .dashboard-div-card .subTitle {
    color: #666666;
    font-size: 14px;
    display: flex;
    gap: 8px;
  }
  .subUnit {
    font-size: 20px;
  }
  .subTitle img {
    width: 19px;
  }

  .data-count {
    margin-left: 7%;
  }

  .data-amount {
    margin-left: 7%;
  }
  .data-rate {
    margin-left: 7%;
  }
  .split-line {
    margin-left: 7%;
    width: 1px;
    height: 99px;
    width: 1px;
    height: 71px;
    margin-bottom: 28px;
    border-left: 1px solid #999999;
  }
  .data-incr {
    margin-left: 7%;
  }
}

.home-div-infoContainer {
  display: flex;
  margin-top: 192px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;

  .card-div-container {
    position: absolute;
    top: 10%;
    left: 43%;
    //transform: translateX(-50%);

    background: #1e43ad;
    border: 1px solid #707070;
    border-radius: 10px;

    width: 270px;
    height: 192px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;

    .card-div-title {
      color: #fff;
      font-size: 14px;
    }
    .card-div-count {
      color: #fff;
      font-size: 60px;
      font-weight: lighter;
    }
  }

  .container__half {
    flex: 1;
    dl {
      cursor: pointer;
    }
  }
  .scroller {
    height: 370px;
    overflow-y: hidden;
  }

  .infoContainer-div-blockTitle {
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    color: #1e43ad;
  }
  .infoContainer-div-title {
    font-size: 48px;
  }
  .news-dl-list:first-of-type {
    margin-top: 70px;
  }

  .news-dl-list {
    /* Content is center horizontally */
    align-items: center;
    display: flex;

    /* Spacing */
    margin: 0px;
    padding: 8px 0px;

    dd {
      margin-left: 45px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 390px;
    }
    .img {
      width: 14px;
      height: 14px;
    }
  }

  .shanghai-svgContainer {
    background: #cccccc;
    svg path:hover {
      cursor: pointer;
    }
    svg path.active {
      fill: $mapActiveColor;
    }
  }
}

.home-div-serviceContainer {
  display: flex;
  margin-top: 64px;
  position: relative;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;

  .service-div-banner {
    min-width: 100%;
    height: 480px;
    border-radius: 15px;
    margin-left: -16px;
    margin-right: -16px;
    overflow-x: hidden;
    background: linear-gradient(to right, rgb(29, 68, 172), rgb(2, 23, 68));
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;

    .service-navContainer {
      position: absolute;
      bottom: 32px;
      color: #fff;
      z-index: 2;
      padding-left: 4%;
      .item-activeSign {
        width: 92px;
        height: 34px;
        top: 34px;
        position: relative;
        transition: left 300ms;
        border-bottom: 1px solid #ffffff;
      }
      .service-navItem {
        display: inline-block;
        font-size: 16px;
        width: 92px;
        text-align: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #aaaaaa;
      }
      .service-navItem:hover {
        cursor: pointer;
      }
    }

    .serviceBanner-item {
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-block;
      box-sizing: border-box;
      color: #fff;
      padding-left: 4%;
      transition: transform 300ms;
      .serviceTitle-en {
        font-size: 20px;
        margin-top: 44px;
      }
      .serviceTitle-zh {
        font-size: 40px;
        margin-top: 5px;
        width: 133px;
        padding-bottom: 6px;
        border-bottom: 1px solid #fff;
      }

      .bannerTitle {
        margin-top: 80px;
        font-size: 30px;
        line-height: 36px;
        max-width: calc(100% - 610px);
        word-break: break-word;
        word-wrap: break-word;
        word-break: break-word; /* 文本行的任意字内断开 */
        word-wrap: break-word; /* IE */
        white-space: -moz-pre-wrap; /* Mozilla */
        white-space: -o-pre-wrap; /* Opera 7 */
        white-space: pre; /* CSS2 */
        white-space: pre-wrap; /* CSS 2.1 */
        white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
      }
      .bannerSubTitle {
        font-size: 16px;
        line-height: 36px;
        max-width: calc(100% - 610px);
        word-break: break-word;
        word-wrap: break-word;
        word-break: break-word; /* 文本行的任意字内断开 */
        word-wrap: break-word; /* IE */
        white-space: -moz-pre-wrap; /* Mozilla */
        white-space: -o-pre-wrap; /* Opera 7 */
        white-space: pre; /* CSS2 */
        white-space: pre-wrap; /* CSS 2.1 */
        white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
      }
      .service-coverImg {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .bannerItem-div-container {
      width: 100%;
      height: 100%;
    }
  }

  .service-div-title {
    height: 160px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .enTitle {
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    color: #1e43ad;
  }

  .zhTitle {
    font-size: 48px;
  }

  .service-div-cards {
    display: flex;
    margin-top: 64px;
    flex-wrap: wrap;
    gap: 32px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .cards__item {
    /* There will be 4 cards per row */
    flex-basis: calc(25% - 24px);
    border: 1px solid #dddddd;
    padding: 32px;
    border-radius: 10px;
    box-sizing: border-box;

    .cards-icon {
      height: 96px;
      text-align: center;
    }
    .cards-icon svg {
      height: 100%;
    }
    .cards-title {
      margin-top: 27px;
      color: #1e43ad;
      font-size: 30px;
      text-align: center;
    }
    .cards-title-en {
      font-size: 20px;
    }
    .cards-desc {
      font-size: 14px;
      margin-top: 41px;
    }
  }

  .cards__item:hover {
    cursor: pointer;
    border: 1px solid #dddddd;
  }
  .cards__item:hover {
    background: #1e43ad;
    color: #fff !important;
  }
  .cards__item:hover .cards-title {
    color: #fff !important;
  }
  .cards__item:hover svg {
    .svg-border {
      opacity: 1;
      stroke-width: 1;
      stroke: #fff;
    }
    .svg-fill {
      fill: rgba(0, 0, 0, 0);
    }
  }
}

.home-div-activityContainer {
  margin-top: 128px;
  margin-bottom: 128px;
  width: 80%;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.home-div-activityContainer img {
  width: 100%;
}

@media (max-width: 1530px) {
  .banner-div-dashboard {
    /*  1620*0.9=1460  */
    zoom: 0.9;
  }
}

@media (max-width: 1395px) {
  .banner-div-dashboard {
    /*  1620*0.9=1460  */
    zoom: 0.8;
  }
}
