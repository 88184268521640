/* 顶部标题 */
.activity-div-titleContainer {
  align-items: center;
  height: 160px;
  /*  width: calc(100% - 64px);*/
  max-width: 1792px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 80px;
  justify-content: center;

  .titleContainer-div-content {
    width: 220px;
    padding: 0 24px;
    font-size: 48px;
    text-align: center;

    .zh {
      font-size: 28px;
    }
    .en {
      font-size: 16px;
      color: #666666;
    }
  }

  .titleContainer-div-separator {
    border-bottom: 1px solid #1e43ad;
    height: 1px;
    width: calc(50% - 430px);
  }
}

.titleContainer-div-intro {
  font-size: 18px;
  padding-left: 60px;
  padding-right: 60px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;

  .subTitle {
    color: #6a9bff;
    font-style: italic;
    text-align: center;
    margin-top: 100px;
  }
  .activityLink {
    font-size: 14px;
    color: #666666;
    text-align: center;
    margin-top: 40px;
  }
}
