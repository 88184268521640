html {
  width: 100%;
  /* height: 100%;*/
}
img {
	border-style: none;
	vertical-align: inherit!important;
}
body {
  width: 100%;
  /*height: 100%;*/
  margin: 0;
  font-family: apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: inline-block;
  min-height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  /*  background-image: url("./images/bg.png");*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background: hsla(0, 0%, 100%, 0.6);
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(95, 95, 95, 0.4);
  transition: all 0.2s;
  border-radius: 0.5rem;

  &:hover {
    background-color: rgba(95, 95, 95, 0.7);
  }
}
