$mapActiveColor: #1e43ad;
.map-div-container {
  display: inline-block;
  width: 100%;
  height: 674px;
  margin-top: 32px;
  padding: 34px 17px;
  box-sizing: border-box;
  position: relative;
}

.map-div-title {
  font-size: 16px;
  position: absolute;
  left: 30px;
  top: 50px;
  z-index: 2;
}
.map-div-num {
  font-size: 72px;
  position: absolute;
  left: 30px;
  top: 60px;
  z-index: 2;
}

.map-div-svgContainer {
  width: 100%;
  height: 100%;
  background: #d8d8d8;
  position: relative;
}
.map-div-svgContainer {
  svg path:hover {
    cursor: pointer;
  }
  svg path.active {
    fill: $mapActiveColor;
  }
}

.map-div-barChart {
  height: 200px;
  width: 100%;
  position: absolute;
  /*  background: pink;*/
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.barChart-div-bar {
  display: inline-block;
  width: 18px;
  background: #ffffff;
  position: relative;
}

.bar-div-data {
  font-size: 14px;
  position: absolute;
  top: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(calc(100% + 10px));
  line-height: 18px;
  color: #ffffff;
}

.bar-div-title {
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  text-align: center;
  width: 32px;
  transform: translate(-50%, calc(100% + 6px));
}

.barChart-div-bar.active {
  background: $mapActiveColor;
}
.active {
  .bar-div-data {
    color: $mapActiveColor;
  }
  .bar-div-title {
    color: $mapActiveColor;
  }
}
