.jury-div-cards {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  justify-content: space-between;
  /*  margin-left: -8px;
  margin-right: -8px;*/

  .jury-item {
    flex-basis: 30%;
    margin-bottom: 60px;
    border-radius: 10px;
    border: 1px solid #dddddd;
  }
}
.jury__line {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.jury-div-cardItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 12px;
  position: relative;

  .jury__header {
    /*    height: 300px;*/
    width: 186px;
    height: 186px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .cover-zhName {
    margin-top: 35px;
    font-size: 24px;
  }
  .cover-enName {
    margin-top: 35px;
    font-size: 14px;
  }

  .jury__info {
    /* Take available height */
    position: absolute;
    top: 0;
    border-radius: 10px;
    flex: 1;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    /*min-height: 260px;*/
    width: 100%;
    height: 100%;
    background-color: rgba(30, 67, 173, 0.9);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    box-shadow: 7px 7px 5px rgba(49, 123, 244, 0.25);
    display: none;

    li {
      text-align: center;
      line-height: 28px;
      font-size: 15px;
      color: #ffffff;
    }

    .zhName {
      font-size: 30px;
      line-height: 38px;
      margin-top: 42px;
    }
    .enName {
      margin-top: 20px;
      color: #ffffff;
    }
    .info p {
      max-width: 267px;
      line-height: 32px;
      margin: 0 auto;
      margin-top: 12px;
    }
  }
}

.jury-div-cardItem:hover {
  cursor: pointer;
  .jury__info {
    display: block !important;
  }
}
