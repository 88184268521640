$hexagon-color: #f5f5f5;

.hexagonContainer {
  position: absolute;
  width: 400px;
  height: 354px;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  -moz-transform: translate(calc(-50% + 45px), 30px);
}

.hexagon {
  zoom: 1.3;
  display: inline-block;
  width: 100px;
  height: 55px;
  background: $hexagon-color;
  position: absolute;
}
.hexagon:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 25px solid $hexagon-color;
}
.hexagon:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 25px solid $hexagon-color;
}

.hexagon-title {
  font-size: 13px;
  zoom: 0.7;
  -moz-transform: scale(0.7); /*火狐专用*/
  text-align: center;
  margin-top: -8px;
}

.hexagon-data {
  margin-top: -3px;
  font-size: 36px;
  font-weight: lighter;
  color: #1d44ac;
  text-align: center;
}

.hexagon-icon {
  width: 42px;
  height: 42px;
  margin-left: 50%;
  transform: translateX(-50%);
}
