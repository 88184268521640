.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.form-label {
  color: #999;
  margin-bottom: 15px;
}
.uploadButton {
  width: 216px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid #1e43ad;
  margin-top: 33px;
}

.formItem-label {
  .ant-form-item-label-left {
    max-width: 300px;
    flex: 0 0 150px;
  }
}
