.news-div-container {
  display: inline-block;
  width: calc(100% - 197px);
  height: 100px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 12px;
}
.news-div-container {
  ul li:not(:first-child) {
    margin-top: 9px;
  }

  .news-div-title {
    display: inline-block;
    max-width: calc(100% - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .news-div-time {
    float: right;
    width: 32px;
  }
}
