@import "./user/login/login";
@import "./home/home";
@import "./data-cockpit/data-board";
@import "./data-cockpit/news/news";
@import "./data-cockpit/weather/weather";
@import "./activity/intro/intro";
@import "./activity/organization/org";

@import "./activity/title/sub-title";
@import "./activity/jury-card/jury-card";
@import "./activity/notice/notice";
@import "./activity/activity";
@import "./activity/enter/enter";
@import "./patent/patent";

@import "~antd/lib/carousel/style/index.css";
@import "~antd/lib/form/style/index.css";
@import "~antd/lib/button/style/index.css";
@import "~antd/lib/checkbox/style/index.css";
@import "~antd/lib/grid/style/index.css";
@import "~antd/lib/icon/style/index.css";
@import "~antd/lib/input/style/index.css";
@import "~antd/lib/radio/style/index.css";
@import "~antd/lib/upload/style/index.css";
@import "~antd/lib/message/style/index.css";
@import "~antd/lib/rate/style/index.css";
@import "~antd/lib/alert/style/index.css";
@import "~antd/lib/pagination/style/index.css";
@import "~antd/lib/select/style/index.css";
@import "~antd/lib/input/style/index.css";
@import "~antd/lib/list/style/index.css";

@import "~antd/lib/style/index.css";

@import "~antd/lib/menu/style/index.css";
@import "~antd/lib/dropdown/style/index.css";
@import "~antd/lib/notification/style/index.css";
/*

@import "~antd/lib/style/index.css";
*/

/*@import "~antd/dist/antd.css";*/
