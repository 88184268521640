.main-div-container {
  position: relative;
  min-height: 820px;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.leftNav-div-container {
  position: absolute;
  top: 0px;
  width: 278px;
  background-color: #fff;
  margin-bottom: 50px;


  li {
    font-size: 20px;
    margin-top: 40px;
    /* font-weight: lighter;*/
  }
  span {
    padding-bottom: 8px;
  }
  li:hover {
    cursor: pointer;
  }
  .active span {
    border-bottom: 6px #1e43ad solid;
  }

  .hr {
    border-bottom: 1px solid #eeeeee;
  }
  button {
    border-radius: 12px;
    background-color: #1e43ad;
    color: #ffffff;
    font-size: 20px;
    border: none;
    height: 64px;
    width: 216px;
  }
}
.blueButton {
  border-radius: 12px;
  background-color: #1e43ad;
  color: #ffffff;
  font-size: 20px;
  border: none;
  height: 64px;
  width: 216px;
}

.outlineTitle {
  font-size: 28px;
  margin-top: 60px;
  label {
    border-bottom: 6px #1e43ad solid;
    padding-bottom: 13px;
    display: inline-block;
  }
}

.subTitle-zh {
  font-size: 20px;
  color: #1e43ad;
  margin-top: 20px;
  label {
    font-size: 14px;
    color: #000;
  }
}

.content-div-container {
  /*  width: calc(100% - 287px);*/
  margin-left: 287px;
  padding-left: 80px;
}

.activityDetail-div-container {
  margin-bottom: 60px;
  margin-top: 80px;

  p {
    /* text-indent: 46px;*/
    /*color: #666666;*/
    color: #000000;
    line-height: 28px;
    font-size: 14px;
    margin-top: 20px;
    max-width: 800px;
  }

  .subTitle-en {
    font-size: 14px;
    color: #1e43ad;
    margin-top: 5px;
  }
  .orgUnit-div-container {
    display: inline-block;
    width: 256px;
    height: 62px;
    font-size: 19px;
    text-align: center;
    line-height: 62px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #dddddd;
    margin-top: 20px;
    margin-right: 19px;
  }

  .progressStep-div-container {
    width: 100%;
    margin-top: 34px;
    display: flex;
    .step-div-container {
      display: inline-block;
      min-height: 400px;
      width: 25%;
      .stepTitle {
        color: #1e43ad;
        font-size: 14px;
        text-align: center;
      }
      .stepSign-div-container {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .stepPoint {
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #1e43ad;
        padding-left: 2px;
        padding-right: 2px;
        box-sizing: border-box;
      }
      .leftLine {
        display: inline-block;
        width: calc(50% - 18px);
        border-bottom: 1px solid #1e43ad;
      }
      .rightLine {
        display: inline-block;
        width: calc(50% - 18px);
        border-bottom: 1px solid #1e43ad;
      }
    }
  }

  .awardItem-div-container {
    display: inline-flex;
    width: 338px;
    height: 100px;
    border-radius: 7px;
    background-color: #eeeeee;
    margin-right: 30px;
    justify-content: space-evenly;
    align-items: center;
	margin-bottom: 30px;

    .awardImg-container,
    .awardDesc-container {
      display: inline-block;
    }
    .awardName {
      height: 36px;
      color: #1e43ad;
      font-size: 20px;
    }
    .awardCount,
    .awardAmount {
      font-size: 14px;
    }
  }
}


.an-card {
  width: 240px;
  height: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.an-card__cover {
  height: 200px;
  width: 100%;
  position: relative;
}
.an-card__cover img {
  width: 100%;
}

.an-card__content {
  /* Take available height */
  flex: 1;
  padding: 15px;
  position: relative;
}

.an-card__title {
  font-size: 16px;
  font-weight: bold;
}
.an-card__date {
  font-size: 13px;
  color: #666666;
}

.an-card__area {
  font-size: 13px;
  color: #666666;
}

.enterButton {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.tablineTitle {
  font-size: 28px;
  margin-top: 60px;
  margin-left: unset;
  margin-right: unset;
  label {
    padding-bottom: 13px;
    display: inline-block;
  }
  label:hover {
    cursor: pointer;
  }
  label.active {
    border-bottom: 6px #1e43ad solid;
  }
}
.ant-btn-primary {
  border-color:  #1e43ad;
  background-color: #1e43ad;
}
.an-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  font-size: 13px;
  color: #FFF;
}

.an-icon:hover {
  cursor: pointer;
}
