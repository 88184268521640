.leftPanel-div-stackAreaBlock {
  width: 100%;
  height: 300px;
  margin-top: 32px;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
}
.pie-div-title {
  position: absolute;
  left: 50%;
  top: 25px;
  font-size: 18px;
  transform: translateX(-50%);
}
.pie-div-title label {
  font-size: 36px;
}
