.has__header .header {
  padding-left: 64px;
  padding-right: 64px;
  box-sizing: border-box;
}

.header {
  padding-top: 50px;
  padding-bottom: 42px;
  min-width: 1280px;
  max-width: 1792px;

  .user {
    display: inline-block;
    width: 70px;
    height: 54px;
    margin-left: 28px;
    :hover {
      cursor: pointer;
    }
    img {
      max-width: 48px;
    }

    .logout {
      height: 28px;
      line-height: 28px;
    }
    .logout:hover {
      cursor: pointer;
      background-color: #e3e3e3;
    }

    .dropdown {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .dropdown__content {
      display: none;
      left: 0;
      position: absolute;
      top: 100%;

      background-color: #fff;
      z-index: 9999;

      height: 38px;
      width: 68px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      border-radius: 4px;
    }

    .dropdown:hover .dropdown__content {
      text-align: center;
      font-size: 16px;

      display: block;
    }

    .dropdown {
      img {
        width: 32px;
      }
      label {
        font-size: 14px;
      }
    }
  }

  ul {
    padding: 0px;
  }

  .logo {
    height: 20px;
    width: 25px;
    margin-right: 5px;
  }
  .container {
    /* Content is centered horizontally */
    align-items: center;
    display: flex;
    font-size: 20px;
    /* Reset styles */
    list-style-type: none;
    margin: 0;
  }

  .container__item--right {
    /* Sticks to the right */
    display: flex;
    margin-left: auto;
    /*width: 256px;*/
    height: 48px;
  }

  /*下拉菜单*/
  .dropdown {
    /* Border */
    /*    border: 1px solid rgba(0, 0, 0, 0.3);*/
    display: flex;

    /* Reset list styles */
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .dropdown li {
    /* Spacing */
    padding: 8px;
    /*    font-weight: lighter;*/
    text-align: center;
    /* Used to position the sub dropdown */
    position: relative;
  }
  .dropdown > li {
    margin-right: 35px;
  }
  @media screen and (max-width: 1480px) {
    .dropdown > li {
      margin-right: 30px;
      font-size: 17px;
    }
  }
  @media screen and (max-width: 1378px) {
    .dropdown > li {
      margin-right: 15px;
    }
  }
  @media screen and (max-width: 1305px) {
    .dropdown > li {
      margin-right: 15px;
    }
  }

  /* The sub dropdown */
  .dropdown ul {
    /* Border */
    /* border: 1px solid rgba(0, 0, 0, 0.3);*/
    background: #1e43ad;
    color: #ffffff;
    /* Hidden by default */
    display: none;

    /* Absolute position */
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 1;
    top: 100%;

    /* Reset styles */
    list-style-type: none;
    margin: 0;
    padding: 0;

    /* Width */
    width: 150px;
  }

  .dropdown ul li:hover {
    background: #627cc6;
  }

  /* The second level sub dropdown */
  .dropdown ul ul {
    left: 100%;
    position: absolute;
    top: 0;
  }

  /* Change background color of list item when being hovered */
  .dropdown li:hover {
    cursor: pointer;
    /*    background-color: rgba(0, 0, 0, 0.1);*/
  }

  /* Show the direct sub dropdown when hovering the list item */
  .dropdown li:hover > ul {
    display: block;
  }

  /* 搜索输入框 */
  .search__container {
    display: inline-flex;

    /* If you want to place the icon before the text input */
    flex-direction: row-reverse;
    width: 248px;
    height: 46px;
    /* Border */
    /* border: 1px solid rgba(0, 0, 0, 0.3);*/
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 10px;

    ::placeholder {
      font-size: 20px;
      color: #c4c4c4;
      /*   line-height: 48px;
      vertical-align: middle;*/
    }
  }

  .container__input {
    border-color: transparent;
    /* Take available width */
    font-size: 18px;
    color: #000;
    flex: 1;
    border-radius: 10px;
    border: 0px;
    outline: none;
    width: 198px;
    line-height: normal;
    　　line-height: 48px\9; /* for ie */
  }
}
