.weather-div-container {
  display: inline-block;
  width: 164px;
  height: 100px;
  /*  padding: 16px;*/
  box-sizing: border-box;
  font-size: 12px;
}

.weather-div-container {
  #he-plugin-standard {
    /*    zoom: 0.5;*/
    /*    transform: translate(-20px,-20px);*/
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li:not(:first-child) {
    margin-top: 9px;
  }
  .weather-div-title {
    display: inline-block;
  }
  .weather-div-icon {
    float: right;
    width: 32px;
    margin-top: -14px;
  }
  .weather-div-icon img {
    width: 32px;
    height: 32px;
  }
  .weather-div-desc {
    float: right;
    width: 32px;
  }
}
